import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { storageKeys } from "../utils/constants";

const BackButtonBlock = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const ticketId = localStorage.getItem(storageKeys.TICKET_ID);
    if (ticketId) {
      navigate("/meet-coach");
    }
  }, []);
  return (
    <div></div>
  )
}

export default BackButtonBlock