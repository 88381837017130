import { FormFields } from "./constants";

export const validate = (input: FormFields): string[] => {
  function validateOrThrow(
    value: any,
    fieldName: string,
    fieldType: string
  ): string | undefined {
    if (fieldType === "string") {
      if (!value || value.trim() === "") {
        return `${fieldName} is required.`;
      }
    } else if (fieldType === "number") {
      if (!value || isNaN(value)) {
        return `${fieldName} must be a valid number.`;
      }
    }
  }

  const errors: string[] = [];

  for (const key in input) {
    if (input.hasOwnProperty(key)) {
      const value: any = input[key as keyof typeof input];
      let fieldName: string;
      switch (key) {
        case "phone":
          fieldName = "Phone";
          const phoneWithoutDashes = value.replace(/-/g, "");
          if (!phoneWithoutDashes.match(/^\d{10}$/)) {
            errors.push(`${fieldName} must be a 10-digit number.`);
          }
          break;
        case "member_type":
          fieldName = "Member type";
          const memberTypeError = validateOrThrow(value, fieldName, "string");
          if (memberTypeError) {
            errors.push(memberTypeError);
          }
          break;
        case "gender":
          fieldName = "Gender";
          const genderError = validateOrThrow(value, fieldName, "string");
          if (genderError) {
            errors.push(genderError);
          }
          break;
        case "marital_status":
          fieldName = "Marital status";
          const maritalStatusError = validateOrThrow(
            value,
            fieldName,
            "string"
          );
          if (maritalStatusError) {
            errors.push(maritalStatusError);
          }
          break;
        case "street_address":
          fieldName = "Street address";
          const streetAddresError = validateOrThrow(value, fieldName, "string");
          if (streetAddresError) {
            errors.push(streetAddresError);
          }
          break;
        case "city":
          fieldName = "City";
          const cityError = validateOrThrow(value, fieldName, "string");
          if (cityError) {
            errors.push(cityError);
          }
          break;
        case "state":
          fieldName = "State";
          if (!value || value.length !== 2 || !value.match(/^[A-Za-z]{2}$/)) {
            errors.push(`${fieldName} must be present and must be a 2 letter.`);
          }
          break;
        case "zipcode":
          fieldName = "Zipcode";
          if (!value || !value.match(/^\d{5}$/)) {
            errors.push("Please enter a valid zipcode.");
          }
          break;
        case "apt_floor":
          if (value === null) {
            break;
          }
          break;
        default:
          fieldName = key;
          const fieldType = ["phone", "zipcode"].includes(key)
            ? "number"
            : "string";
          const error = validateOrThrow(value, fieldName, fieldType);
          if (error) {
            errors.push(error);
          }
          break;
      }
    }
  }

  return errors;
};
