import React from "react";
import { common } from "../../../utils/constants";

const PageNotFound: React.FC = () => {
  return (
    <div className="page-center-container margin-content">
      <span className="title">{common.PAGE_NOT_FOUND}</span>
    </div>
  );
};

export default PageNotFound;
