import React from "react";
import AgeGate from "./AgeGate";
import FixedHeaderUserLayout from '../../layout/FixedHeaderUserLayout'
import BackButtonBlock from "../../../routes/BackButtonBlock";

const index :React.FC = () => {
  return (
    <div>
      <BackButtonBlock />
      <FixedHeaderUserLayout children={<AgeGate />} />
    </div>
  )
};

export default index;
