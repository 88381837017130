import algoliasearch from "algoliasearch/lite";
import { useLocation } from "react-router-dom";
import { InstantSearch, connectHits } from "react-instantsearch-dom";
import CustomHits from "./customHits";
import EnvironmentConfig from "../../config/EnvironmentConfig";

interface Hit {
  [key: string]: any;
}

const searchClient = algoliasearch(EnvironmentConfig.algoliaAppID, EnvironmentConfig.algoliaAPIKey);

function ProviderData() {
  const location = useLocation();
  const indexName = location.state;

  const Hits = connectHits<{ hits: Hit[] }>(({ hits }: { hits: Hit[] }) => (
    <CustomHits hits={hits} indexName={indexName} />
  ));
  
  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <Hits />
      </InstantSearch>
    </div>
  );
}

export default ProviderData;
