import "./AgeGate.scss";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { CCADatePicker } from "../../common/CCADatePicker/CCADatePicker";
import { ageGate, common, storageKeys } from "../../../utils/constants";
import { calculateAge } from "../../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";

const AgeGate: React.FC = () => {
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isValidDate, setIsValidDate] = useState<boolean>(false);
  const name: string | null = localStorage.getItem(storageKeys.FIRST_NAME) || "";
  const navigate = useNavigate();
  const today = new Date();

  useEffect(() => {
      if(birthDate == null) {
        setIsValidDate(false);
      } else if(isNaN(birthDate as any)) {
        setIsValidDate(false);
      } else if (birthDate > new Date()) {
        setError(ageGate.ERROR);
        setIsValidDate(false);
      } else {
        setIsValidDate(true);
      }
  }, [birthDate]);

  const handleDateChange = (date: Date | null) => {
    setError(null);
    setBirthDate(date);
  };

  const handleConfirm = () => {
    if (isValidDate) {
      const _birthDate = moment(birthDate).format("DD-MM-YYYY");
      const mm_dd_yy = moment(birthDate).format("MM-DD-YYYY");
      localStorage.setItem(storageKeys.BIRTH_DATE, mm_dd_yy);
      const age: number = calculateAge(_birthDate);
      if (age < 18) {
        localStorage.setItem(storageKeys.RESGISTRATION_BLOCK, "true");
        navigate("/call-page");
      } else {
        localStorage.setItem(storageKeys.AGE, age.toString());
        navigate("/question-one");
      }
    }
  };

  const setDefaultDate = () => {
    // Calculate the date 18 years ago from today
    if (!birthDate) {
      const defaultBirthDate = new Date();
      defaultBirthDate.setFullYear(defaultBirthDate.getFullYear() - 18);
      setBirthDate(defaultBirthDate);
    }
  };

  return (
    <div className="page-center-container margin-content age-gate-page">
      <span className="subtitle">{ageGate.SUBTITLE}</span>
      <span className="title">
        Hello&nbsp;{name.charAt(0).toUpperCase() + name.substring(1)}!
      </span>
      <span className="title">{ageGate.QUESTION}</span>
      <div className="date-picker">
        <CCADatePicker
          value={birthDate}
          placeholder="Enter your birthdate"
          onChange={(date: Date | null) => handleDateChange(date)}
          onClick={setDefaultDate}
          maxDate={today}
        />
      </div>
      {error && <span className="error">{error}</span>}
      <div className="button-div">
        <button
          className={isValidDate ? "question-button-yes" : "question-button-yes-disable"}
          onClick={handleConfirm}
          disabled={!isValidDate}
        >
          {common.NEXT}
        </button>
      </div>
    </div>
  );
};

export default AgeGate;
