import { useLocation, useNavigate } from "react-router-dom";
import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import { questions } from "../../../utils/constants";
import QuestionPage from "../../common/CommonQuestion";
import BackButtonBlock from "../../../routes/BackButtonBlock";

const Questions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  let pathOne = "/question-two";
  let pathTwo = "/question-three";
  let pathThree = "/user-info";

  const handleResponseNo = (question: string, urlpath: string) => {
    localStorage.setItem(question, "No");
    navigate(urlpath);
  };

  return (
    <div>
      <BackButtonBlock />
      {currentPath === "/question-one" && (
        <FixedHeaderUserLayout
          children={
            <QuestionPage
              title={questions.QUESTION_ONE}
              targetPath={pathOne}
              handleResponseNo={(question: string, urlpath: string) =>
                handleResponseNo(question, urlpath)
              }
            />
          }
        />
      )}
      {currentPath === "/question-two" && (
        <FixedHeaderUserLayout
          children={
            <QuestionPage
              title={questions.QUESTION_TWO}
              targetPath={pathTwo}
              handleResponseNo={(question: string, urlpath: string) =>
                handleResponseNo(question, urlpath)
              }
            />
          }
        />
      )}
      {currentPath === "/question-three" && (
        <FixedHeaderUserLayout
          children={
            <QuestionPage
              title={questions.QUESTION_THREE}
              targetPath={pathThree}
              handleResponseNo={(question: string, urlpath: string) =>
                handleResponseNo(question, urlpath)
              }
            />
          }
        />
      )}
    </div>
  );
};

export default Questions;
