import React, { useContext, useState } from 'react';
import { MenuItem, Box, Button, Menu } from '@mui/material';
import TickButton from '../../assests/icons/check-button.png'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { updateEapxCase } from '../../../utils/ApiClient';
import globalContext from '../../../context/globalContext';
import { isProviderApproved } from '../../../utils/helperFunctions';
import ChaningStatusDialog from '../DialogBox/ConfirmDialogBox';
import { caseStatusChangeDialog } from '../../../utils/constants';
import './DropdownWithConfirmationBar.scss'

interface CustomSelectWithButtonsProps {
  options: any[];
  name: string;
  stateChange: (state: string) => void;
  state: any;
  showButtons: boolean;
  fileNo: any;
  showPopUp: boolean;
  dialogTitle?: string;
  onConfirm?: () => void;
  confirmLabel?: string;
  activeCase?: string;
  additionalProviders?: any;
  setSuccessMessage?: (message: any) => void;
  setCaseStatus?: (state: string) => void;
  casesTypeName: string;
  caseStatus?: string
}

const DropdownWithConfirmationBar: React.FC<CustomSelectWithButtonsProps> = ({ casesTypeName, options, name, stateChange, state, caseStatus, showButtons, fileNo, showPopUp, additionalProviders, activeCase, setSuccessMessage, setCaseStatus }) => {
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isMenuDisabled = (casesTypeName === "Closed" && name !== "eapx_file_status") ? true : false
  const [valueToSend, setValueToSend] = useState<string>("")
  const { globalErrorHandler }: any = useContext(globalContext)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    if (name === "eapx_file_status") {
      if (caseStatus) {
        stateChange(caseStatus)
        if (setCaseStatus) {
          setCaseStatus(caseStatus)
        }
      }
      setOpenDialogBox(false)
      setAnchorEl(null)
    } else {
      stateChange("")
      setAnchorEl(null)
    }
  };

  const updateCase = async (option: string): Promise<void> => {
    const data = { [name]: option }
    try {
      const response = await updateEapxCase(fileNo, data)
      if (setCaseStatus) {
        setCaseStatus(state)
      }
      if (setSuccessMessage) {
        setSuccessMessage(response.data.message);
      }
      setAnchorEl(null);
      setOpenDialogBox(false)
    } catch (error) {
      if (caseStatus && name === "eapx_file_status") {
        stateChange(caseStatus)
      }
      stateChange("")
      globalErrorHandler(error)
      setAnchorEl(null);
    }
  }

  const handleSave = async (state: string): Promise<void> => {
    if (name === "eapx_file_status" && state === "provider_accepted_case") {
      const result = isProviderApproved(additionalProviders, activeCase)
      if (!result) {
        if (showPopUp) {
          setOpenDialogBox(true)
        }
      } else {
        updateCase(state)
      }
    } else {
      updateCase(state)
    }
  };

  const handleMenuItemClick = (value: any) => {
    if (value.name) {
      setValueToSend(value.value)
    } else {
      setValueToSend(value)
    }
    stateChange(value.name ? value.name : value);
  };

  return (
    <Box>
      <Button
        aria-controls="assigned-specialist-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          width: '100%',
          justifyContent: 'space-between',
          textAlign: 'left',
          backgroundColor: 'white',
          color: '#000000',
        }}
        variant='contained'
        color='info'
        endIcon={<ArrowDropDownIcon />}
      >
        {state || name}
      </Button>
      <Menu
        id="assigned-specialist-menu"
        anchorEl={anchorEl}
        open={isMenuDisabled ? false : Boolean(anchorEl)}
        onClose={handleClose}
        defaultValue={state}
        MenuListProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : '100',
            paddingBottom: '0px'
          }
        }}
      >
        {options.map((option: any, index: number) => {
          return (
            <MenuItem
              key={option.value || option}
              onClick={() => handleMenuItemClick(option)}
              style={state === (option.name || option) ? { fontWeight: "600" } : {}}
              disabled={name === "assigned_specialist" && index === 0}
            >
              {option.name ? option.name : option}
              {state === (option.name || option) && (
                <img src={TickButton} alt="checked" style={{ marginLeft: 'auto' }} />
              )}
            </MenuItem>
          )
        }
        )}
        <Box>
          <div className={showButtons ? 'button-area' : 'hide-div'}>
            <button onClick={handleCancel} className='cancel-btn'>
              {"Cancel"}
            </button>
            <button onClick={() => handleSave(valueToSend)} className='save-btn'>
              {"Save"}
            </button>
          </div>
        </Box>
        <ChaningStatusDialog
          title={caseStatusChangeDialog.title}
          content={caseStatusChangeDialog.content}
          onConfirm={() => updateCase(valueToSend)}
          handleClose={handleCancel}
          confirmLabel={"Confirm"}
          openDialogBox={openDialogBox} />
      </Menu>
    </Box>
  );
};

export default DropdownWithConfirmationBar;
