import {
  LS_PHONE_NUMBER,
  callPage,
  common,
  questions,
  storageKeys,
} from "../../utils/constants";
import ProgressBar from "./Progressbar/ProgressBar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { EmergencyDisclaimer } from "./EmergencyDisclaimer/EmergencyDisclaimer";

const QuestionPage = (props: any) => {
  let targetPath = props.targetPath;
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState<string>(callPage.NUMBER);

  const handleResponseYes = (answer: string) => {
    localStorage.setItem(props.title, answer);
    localStorage.setItem(storageKeys.RESGISTRATION_BLOCK, answer);
    navigate("/call-page");
  };

  useEffect(() => {
    setPhoneNumber(localStorage.getItem(LS_PHONE_NUMBER) ?? callPage.NUMBER);
  }, []);

  return (
    <div className="page-center-container">
      <EmergencyDisclaimer phoneNumber={phoneNumber} />

      <span className="subtitle">{questions.EXP_SUBTITLE}</span>
      <span className="title">{props.title}</span>
      <div className="question-button-div">
        <button
          name="No"
          className="question-button-no"
          onClick={() => props.handleResponseNo(props.title, targetPath)}
        >
          {common.NO}
        </button>
        <button
          name="Yes"
          className="question-button-yes"
          onClick={() => handleResponseYes(common.YES)}
        >
          {common.YES}
        </button>
      </div>
      <ProgressBar />
    </div>
  );
};

export default QuestionPage;
