import React, { useState, useEffect, useRef, useContext } from 'react'
import EAPCaseSideBar from '../../common/SideTabs/eap-cases-sidebar/EAPCaseSideBar'
import { getEAPCases, updateEapxCase } from '../../../utils/ApiClient'
import globalContext from '../../../context/globalContext'
import Loader from '../../common/Loader/Loader'
import AdditionalProviders from './AdditionalProviders'
import './PRTDashboardHome.scss'
import CaseDetails from './CaseDetails'
import { caseType } from '../../../utils/constants'

type Provider = {
    eapx_case: string;
    eapx_file_number: number;
    id: number;
    provider_detail: string;
    status: string;
    full_address: string;
    phone: string;
    email: string;
    notes: string;
};

const PRTDashboardHome: React.FC = () => {
    const [activeCase, setActiveCase] = useState<any>("")
    const [cases, setCases] = useState<any[]>([])
    const [caseStatus, setCaseStatus] = useState<string>("")
    const [activeCaseInfo, setActiveCaseInfo] = useState<any>()
    const [casesTypeName, setCasesTypeName] = useState<string>("My Cases")
    const [personalInfo, setPersonalInfo] = useState<any>({})
    const [presentCondition, setPresentCondition] = useState<any>({})
    const [customQuestion, setCustomQuestion] = useState({
        appointment_date_preference: "",
        distance_willing_to_travel: "",
        provider_preference: "",
        well_being_score: ""
    })
    const [loading, setLoading] = useState<boolean>(true)
    const [caseLoading, setCaseLoading] = useState<boolean>(false)
    const [additionalProviders, setAdditionalProviders] = useState<{ [caseId: string]: Provider[] }>({});
    const [noData, setNoData] = useState(false)
    const [specialist, setSpecialist] = useState("")
    const [caseClaimed, setCaseClaimed] = useState<boolean>(false)
    const [fileNo, setFileNo] = useState<number>(0)
    const { globalErrorHandler }: any = useContext(globalContext)
    const [notes, setNotes] = useState<string>("")
    const [emptyNotes, setEmptyNotes] = useState<boolean>(false)
    const disbableButton = (casesTypeName === caseType[1].name)
    const [successMessage, setSuccessMessage] = useState<string>("")
    const effectRef = useRef(false)

    const addProvider = () => {
        if (activeCase) {
            const newProvider: Provider = {
                id: 0,
                provider_detail: '',
                status: '',
                notes: '',
                full_address: '',
                email: '',
                phone: '',
                eapx_case: activeCase,
                eapx_file_number: fileNo
            };
            setAdditionalProviders((prevState: any) => ({
                ...prevState,
                [activeCase]: [...(prevState[activeCase] || []), newProvider]
            }));
        }
    };

    const caseData = (data: any) => {
        if (data !== null || data !== undefined) {
            setFileNo(data?.file_number)
            setPersonalInfo(data?.patient_information)
            setPresentCondition(data?.presenting_condition.name)
            setSpecialist(data?.assigned_specialist)
            setCaseStatus(data?.eapx_file_status_display)
            setCustomQuestion({
                appointment_date_preference: data?.appointment_date_preference,
                distance_willing_to_travel: data?.distance_willing_to_travel,
                provider_preference: data?.provider_preference,
                well_being_score: data?.well_being_score
            })
            setNotes(data?.notes)
        }
    }

    const getEapCaseData = async (): Promise<void> => {
        try {
            const response = await getEAPCases("assigned_to_team,provider_search_in_progress")
            if (!response.data.data.cases.length && response.data.data.first_case_detail === null) {
                setNoData(true)
            }
            else {
                setCases(response.data.data.cases);
                setActiveCase(response.data.data.first_case_detail.id)
                setActiveCaseInfo(response.data.data.first_case_detail)
                setFileNo(response.data.data.first_case_detail.file_number)
                caseData(response.data.data.first_case_detail)
                setNoData(false)
            }
            setLoading(false)
            setCaseLoading(false)
        } catch (error) {
            globalErrorHandler(error)
            setLoading(false)
            setCaseLoading(false)
        }
    }

    const saveNotes = async (): Promise<void> => {
        if (!notes) {
            setEmptyNotes(true)
        } else {
            let note = notes
            const data = { notes: notes }
            try {
                const response = await updateEapxCase(fileNo, data)
                setNotes(note)
                setSuccessMessage(response.data.message)
                setEmptyNotes(false)
            } catch (error) {
                globalErrorHandler(error)
            }
        }
    }

    useEffect(() => {
        if (effectRef.current === false) { getEapCaseData() }
        return () => { effectRef.current = true }
    }, [])

    useEffect(() => {
        if (caseClaimed) {
            getEapCaseData()
        }
    }, [caseClaimed])

    useEffect(() => {
        if (activeCase && activeCaseInfo) {
            setAdditionalProviders((prevState: any) => ({
                ...prevState,
                [activeCase]: activeCaseInfo.provider_outreach.map((value: any) => ({ ...value, status: "Saved!" }))
            }));
        }
    }, [activeCaseInfo]);

    useEffect(() => {
        if (successMessage) {
            setTimeout(() => {
                setNotes(notes)
                setSuccessMessage("")
            }, 5000)
        }
    }, [successMessage])

    useEffect(() => {
        if (emptyNotes) {
            setTimeout(() => {
                setEmptyNotes(false)
            }, 5000)
        }
    }, [emptyNotes])

    useEffect(() => {
        if (cases?.length) {
            caseData(activeCaseInfo)
        }
    }, [activeCase, activeCaseInfo, cases, casesTypeName])

    return (
        <div className='eap-cases-page'>
            {
                loading ? <div className='loader-component-section'> <Loader /></div> :
                    <div className='case-info-section'>
                        <EAPCaseSideBar
                            cases={cases}
                            setActiveCaseInfo={setActiveCaseInfo}
                            casesTypeName={casesTypeName}
                            setCases={setCases}
                            setCasesTypeName={setCasesTypeName}
                            activeCase={activeCase}
                            setActiveCase={setActiveCase}
                            setCaseLoading={setCaseLoading}
                            setCaseClaimed={setCaseClaimed}
                            setFileNo={setFileNo}
                            setNoData={setNoData} />
                        {
                            caseLoading ? <div className='case-content'>
                                <Loader />
                            </div> : <React.Fragment>
                                {
                                    noData ? <span className='no-data-section'>{"No cases claimed"}</span> :
                                        <div className='case-content'>
                                            <CaseDetails
                                                activeCaseInfo={activeCaseInfo}
                                                caseStatus={caseStatus}
                                                personalInfo={personalInfo}
                                                caseLoading={caseLoading}
                                                presentCondition={presentCondition}
                                                customQuestion={customQuestion}
                                                setCaseStatus={setCaseStatus}
                                                activeCase={activeCase}
                                                fileNo={fileNo}
                                                additionalProviders={additionalProviders}
                                                setSpecialist={setSpecialist}
                                                specialist={specialist}
                                                casesTypeName={casesTypeName} />
                                            <div className='case-sub-section'>
                                                {additionalProviders[activeCase]?.map((provider: any, index) => (
                                                    <AdditionalProviders
                                                        key={provider.id}
                                                        fileNo={fileNo}
                                                        providerNo={index}
                                                        provider={provider}
                                                        activeCase={activeCase}
                                                        setAdditionalProviders={setAdditionalProviders}
                                                        additionalProviders={additionalProviders}
                                                        caseStatus={caseStatus}
                                                        setCasesTypeName={setCasesTypeName}
                                                        setNoData={setNoData}
                                                        setLoading={setLoading}
                                                        setCases={setCases}
                                                        setActiveCase={setActiveCase}
                                                        setActiveCaseInfo={setActiveCaseInfo}
                                                        casesTypeName={casesTypeName} />
                                                ))}
                                                <button className={disbableButton ? 'hide-div' : 'add-provider-button'}
                                                    onClick={addProvider}>{" + Add additional Provider"}</button>
                                            </div>
                                            <div className='notes-section'>
                                                <textarea
                                                    rows={10}
                                                    placeholder="Notes"
                                                    className='notes-textarea'
                                                    value={notes}
                                                    onChange={(e) => setNotes(e.target.value)}
                                                    disabled={disbableButton}
                                                />
                                                <div className={disbableButton ? 'hide-div' : 'button-section'}>
                                                    <button className='save-notes' onClick={saveNotes}>{"Save"}</button>
                                                </div>
                                                {
                                                    emptyNotes && <span className='error'>{"Please add notes"}</span>
                                                }
                                                {
                                                    successMessage && <span className='success-message'>{successMessage}</span>
                                                }
                                            </div>
                                        </div>
                                }
                            </React.Fragment>
                        }
                    </div>
            }
        </div>
    )
}

export default PRTDashboardHome 