import { callPage } from "../../../utils/constants";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import "./InvalidLink.scss";

const InvalidLink = () => {
  return (
    <div className="invalid-link-page page-center-container margin-content">
      <span className="subtitle">Seems like you have an invalid link</span>
      <span className="title">Please give us a call at</span>
      <span className="title">{callPage.NUMBER}</span>
      <span className="title">or email us at</span>
      <span className="title email-title">{EnvironmentConfig.supportEmail}</span>
    </div>
  );
};

export default InvalidLink;
