import { useEffect, useState } from "react";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import { homeNavigation } from "../../../utils/homeNavigation";
import Loader from "../../common/Loader/Loader";

const Test = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!EnvironmentConfig.enableTestPage) {
      homeNavigation();
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <div className="page-center-container test-body">
      {loading ? (
        <Loader />
      ) : (
        <div className="page-center-container">
          {EnvironmentConfig.testOids.map((item, index) => {
            return (
              <a
                href={`/?oid=${item.oid}`}
                key={"url" + index}
                className="nav-link"
                style={{ padding: "10px" }}
              >
                {item.name}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Test;
