import React from "react";
import { getFormattedDate } from "../../utils/helperFunctions";
import './ConversationList.scss'

interface ConversationListProps {
  tickets: any[];
  handleticket: (ticketId: number) => void;
}

const ConversationList: React.FC<ConversationListProps> = ({
  tickets,
  handleticket,
}) => {
  return (
    <div className="conversation-list-component">
      {tickets?.map((ticket: any, index: number) => {
        const ticket_date: any = getFormattedDate(ticket.created_at);
        return (
          <div className="conversation-list" key={index + ticket.ticket_id}>
            <div className="ticket-div">
              <div className="ticket-info">
                <span className="ticket-date">{ticket_date}</span>
                <span className="ticket-status">
                  {ticket.is_ticket_open === true ? "Ongoing" : "Closed"}
                </span>
              </div>
              <div className="button-section">
                <button
                  className="view-button"
                  onClick={() => handleticket(ticket.ticket_id)}
                >
                  {"View"}
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ConversationList;
