import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { storageKeys } from "../utils/constants";

const AuthenticationCheck = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem(storageKeys.TOKEN);
    if (token) {
      navigate("/account-info");
    }
  }, []);
  return (
    <div></div>
  )
}

export default AuthenticationCheck
