import React from "react";
import { Navigate } from "react-router-dom";
import { storageKeys } from "../utils/constants";

interface AuthenticatedRouteProps {
  allowedType: string;
  children: React.ReactNode;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  allowedType,
  children,
}) => {
  const userType = localStorage.getItem(storageKeys.USER_TYPE);
  if (!userType || allowedType !== userType) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default AuthenticatedRoute;
