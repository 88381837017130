import { common } from "../../../utils/constants";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="loader-component">
      <div className="loader"></div>
      <span>{common.LOADING}</span>
    </div>
  );
};

export default Loader;
