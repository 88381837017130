import React, { useState, useContext, useEffect } from 'react'
import DeleteBin from '../../assests/icons/bin.png'
import { addProviderOutReach, assignProvider, deleteProviderOutReach, editProviderOutReach, getEAPCases } from '../../../utils/ApiClient';
import CustomSelectWithButtons from '../../common/DropdownMenu/DropdownWithConfirmationBar';
import ChaningStatusDialog from '../../common/DialogBox/ConfirmDialogBox'
import { caseType, providerStatus, providerAssignDialog, deleteProviderDialog, assigningProviderDialog } from '../../../utils/constants';
import globalContext from '../../../context/globalContext';
import { validateRequiredFields, extractDateTime } from '../../../utils/helperFunctions';
import './AdditionalProvider.scss'

interface AdditionalProviderProps {
  provider: any;
  providerNo: number;
  activeCase: any;
  setAdditionalProviders: (activeCase: any) => void;
  additionalProviders: any;
  caseStatus: string;
  fileNo: number;
  setCasesTypeName: (caseTpe: string) => void;
  setNoData: (data: boolean) => void;
  setLoading: (loading: boolean) => void;
  setCases: (cases: any) => void;
  setActiveCaseInfo: (caseInfo: any) => void;
  setActiveCase: (activeCase: any) => void;
  casesTypeName: string
}

const AdditionalProviderComponent: React.FC<AdditionalProviderProps> = ({ provider, providerNo, activeCase, setAdditionalProviders, additionalProviders, fileNo, setCasesTypeName, setActiveCase, setNoData, setLoading, setCases, setActiveCaseInfo, casesTypeName }) => {
  const [providerInfo, setProviderInfo] = useState(provider)
  const [errors, setErrors] = useState<string>("");
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [openAssignProvider, setOpenAssignProvider] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [status, setStatus] = useState<string | null>("")
  const { globalErrorHandler }: any = useContext(globalContext);
  const disabled = (casesTypeName === caseType[1].name)

  useEffect(() => {
    setStatus(provider?.provider_status_display ? provider?.provider_status_display : providerStatus[0].name)
  }, [provider?.provider_status_display])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setProviderInfo((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  }

  const removeProvider = (index: number) => {
    setAdditionalProviders((prevProviders: any) => {
      const updatedProviders = { ...prevProviders };
      updatedProviders[activeCase] = [
        ...prevProviders[activeCase].slice(0, index),
        ...prevProviders[activeCase].slice(index + 1)
      ];
      return updatedProviders;
    });
  }

  const saveProviderDetails = async (): Promise<void> => {
    const result = validateRequiredFields(providerInfo)
    if (!result.valid) {
      setErrors(result.message);
    } else {
      const data = {
        eapx_file_number: fileNo,
        eapx_case: activeCase,
        provider_detail: providerInfo.provider_detail,
        full_address: providerInfo.full_address,
        email: providerInfo.email,
        phone: providerInfo.phone,
        notes: providerInfo.notes
      }
      try {
        const response = await addProviderOutReach(data);
        const newProvider = { ...providerInfo, id: response.data.data.id, status: "Saved" };
        setProviderInfo(newProvider);
        setAdditionalProviders((prev: any) => {
          const newProviders = { ...prev };
          newProviders[activeCase] = newProviders[activeCase].map((provider: any) =>
            provider.id === providerInfo.id ? newProvider : provider
          );
          return newProviders;
        });
      } catch (error) {
        globalErrorHandler(error);
      }
    }
  }

  const changeButtonName = (data:any) => {
    const updatedData = { ...providerInfo, ...data, status: "Save" };
    setProviderInfo(updatedData);
    setAdditionalProviders((prev: any) => {
      const updatedProviders = { ...prev };
      updatedProviders[activeCase] = updatedProviders[activeCase].map((provider: any) =>
        provider.id === providerInfo.id ? updatedData : provider
      );
      return updatedProviders;
    });
  }

  const updateProvider = async (): Promise<void> => {
    const matchedValue = providerStatus.find(item => item.name === status)?.value;
    const data = { ...providerInfo, provider_status: matchedValue };
    delete data.status;
    try {
      await editProviderOutReach(providerInfo.id, data);
      const updatedData = { ...providerInfo, ...data, status: "Saved" };
      setProviderInfo(updatedData);
      setAdditionalProviders((prev: any) => {
        const updatedProviders = { ...prev };
        updatedProviders[activeCase] = updatedProviders[activeCase].map((provider: any) =>
          provider.id === providerInfo.id ? updatedData : provider
        );
        return updatedProviders;
      });
      setTimeout(() => {
        changeButtonName(data)
      }, 5000)
    } catch (error) {
      globalErrorHandler(error);
      setProviderInfo({ ...provider });
    }
  };

  const removeProviderOutreach = async (providerId: number): Promise<void> => {
    try {
      await deleteProviderOutReach(providerId);
      setAdditionalProviders((prev: any) => {
        const updatedProviders = { ...prev };
        updatedProviders[activeCase] = updatedProviders[activeCase].filter(
          (provider: any) => provider.id !== providerId
        );
        return updatedProviders;
      });
    } catch (error) {
      globalErrorHandler(error);
    }
  };

  const handlePopUp = (index: number) => {
    if (providerInfo.id !== 0) {
      setOpenDeleteDialog(true)
    } else {
      removeProvider(index)
    }
  }

  const handleCaseChange = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await getEAPCases(caseType[1].value);
      if (!response.data.data.cases.length && response.data.data.first_case_detail === null) {
        setNoData(true);
        setCases([]);
        setLoading(false);
      } else {
        setNoData(false)
        setCases(response.data.data.cases);
        setActiveCase(response.data.data.first_case_detail.id)
        setActiveCaseInfo(response.data.data.first_case_detail);
        setCasesTypeName(caseType[1].name);
        setLoading(false);
      }
    } catch (error) {
      globalErrorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  const handleCancel = () => {
    if (openDeleteDialog) {
      setOpenDeleteDialog(false)
    }
    if (openDialogBox) {
      setOpenDialogBox(false)
    }
    if (openAssignProvider) {
      setOpenAssignProvider(false)
    }
  }

  const assignProviderOutreach = async (id: number): Promise<void> => {
    try {
      const response = await assignProvider(id)
      const dateAndTime = extractDateTime(response.data.data.assigned_date);
      let _message = "PROVIDER ASSIGNED - " + dateAndTime
      setSuccessMessage(_message)
      handleCancel()
      setTimeout(() => {
        setSuccessMessage('');
        setCasesTypeName(caseType[1].name)
        handleCaseChange()
      }, 10000)
    } catch (error) {
      handleCancel()
      globalErrorHandler(error)
    }
  }

  const handleAssign = async (): Promise<void> => {
    const status = providerStatus[2].value
    if (providerStatus[2].value === providerInfo.provider_status) {
      setOpenAssignProvider(true)
    }
    else if (providerInfo.provider_status !== status) {
      setOpenDialogBox(true)
    }
  }

  return (
    <div className='additional-provider-component'>
      <div className='provider-action-header'>
        <span className='provider-title'>Provider #{providerNo + 1}</span>
        {
          successMessage ? <span className='success message-block'>{successMessage}</span> : <div className='buttons-section'>
            <div className='dropdown-section'>
              <CustomSelectWithButtons
                options={providerStatus}
                name='provider-status'
                stateChange={setStatus}
                state={status}
                showButtons={false}
                fileNo={fileNo}
                showPopUp={true}
                casesTypeName={casesTypeName} />
            </div>
            <div className={casesTypeName === caseType[1].name ? "hide-div" : "buttons"}>
              <img src={DeleteBin} alt='delete' className='delete-icon' onClick={() => handlePopUp(providerNo)} />
              <button
                className='save-button'
                onClick={providerInfo.id > 0 ? updateProvider : saveProviderDetails}>
                {provider.status === "Saved" ? "Saved!" : "Save"}
              </button>
              <button
                className='assign-button'
                onClick={handleAssign}>
                {"Assign"}
              </button>
            </div>
          </div>

        }
      </div>
      <div className='fields'>
        <input type='text' className='input-box' placeholder='First name, last name, license type or group name*' name='provider_detail' value={providerInfo.provider_detail} onChange={handleChange} disabled={disabled} />
        <input type='text' className='input-box' placeholder='Tela health or full address*' name='full_address' value={providerInfo.full_address} onChange={handleChange} disabled={disabled} />
        <input type='text' className='input-box' placeholder='Phone*' name='phone' value={providerInfo.phone} onChange={handleChange} disabled={disabled} />
        <input type='text' className='input-box' placeholder='Email' name='email' value={providerInfo.email} onChange={handleChange} disabled={disabled} />
        <input type='text' className='input-box' placeholder='Note' name='notes' value={providerInfo.notes} onChange={handleChange} disabled={disabled} />
      </div>
      <div>{errors && <span className='error'>{errors}</span>}
      </div>
      <ChaningStatusDialog
        title={providerAssignDialog.title}
        content={providerAssignDialog.content}
        onConfirm={() => assignProviderOutreach(providerInfo.id)}
        handleClose={handleCancel}
        confirmLabel={providerAssignDialog.confirmLabel}
        openDialogBox={openDialogBox} />
      <ChaningStatusDialog
        title={deleteProviderDialog.title}
        content={deleteProviderDialog.content}
        onConfirm={() => removeProviderOutreach(providerInfo.id)}
        handleClose={handleCancel}
        confirmLabel={deleteProviderDialog.confirmLabel}
        openDialogBox={openDeleteDialog} />
      <ChaningStatusDialog
        title={assigningProviderDialog.title}
        content={assigningProviderDialog.content}
        onConfirm={() => assignProviderOutreach(providerInfo.id)}
        handleClose={handleCancel}
        confirmLabel={assigningProviderDialog.confirmLabel}
        openDialogBox={openAssignProvider} />
    </div >
  )
}

export default React.memo(AdditionalProviderComponent)