import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import Login from "./Login";
import AuthenticationCheck from "../../../routes/AuthencticationCheck";

const index = () => {
  return (
    <div>
      <AuthenticationCheck />
      <FixedHeaderUserLayout children={<Login />} hideMyAccButton={true}/>
    </div>
  )
};

export default index;
