import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { storageKeys } from "../utils/constants";

const ServiceCheck = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParam = new URLSearchParams(location.search);
  const flow: any = searchParam.get(storageKeys.FLOW);
  const availableService: any = localStorage.getItem(storageKeys.AVAILABLE_SERVICE);
  const parsedArray = JSON.parse(availableService);
  const convertedArray: any = Array.isArray(parsedArray)
    ? parsedArray
    : [parsedArray];

  useEffect(() => {
    if (!convertedArray.includes(flow)) {
      const oid: any = localStorage.getItem(storageKeys.ORGANIZATION_ID);
      const queryString = new URLSearchParams({
        oid: oid,
      }).toString();
      const newUrl = oid ? `/?${queryString}` : "/";
      navigate(newUrl);
    }
  }, []);

  return <div></div>;
};

export default ServiceCheck;
