import React, { useContext } from 'react'
import { MenuItem } from '@mui/material'
import { claimEapxCase } from '../../../../utils/ApiClient'
import globalContext from '../../../../context/globalContext'
import './CaseList.scss'

interface CaseListProps {
    caseList: any[];
    setCaseClaimed: (claim: boolean) => void;
    setCaseLoading: (load: boolean) => void;
    setSearchTerm: (term: string) => void;
    setOpenList: (open: boolean) => void;
    noCase : boolean;
    searchTerm: string;
}

const CaseList: React.FC<CaseListProps> = ({ caseList, setCaseClaimed, setCaseLoading, setSearchTerm, setOpenList, noCase, searchTerm }) => {
    const { globalErrorHandler }: any = useContext(globalContext)
    const handleClaimCase = async (fileNo: number): Promise<void> => {
        try {
            setCaseClaimed(false)
            await claimEapxCase(fileNo);
            setCaseClaimed(true);
            setSearchTerm("");
            setOpenList(false);
            setCaseLoading(true);
        } catch (error) {
            globalErrorHandler(error)
            setCaseClaimed(false)
        }
    }
    return (
        <div className='case-list-component'>
            {
                noCase ? <span className='no-cases'>"#{searchTerm}" {"Case not found"}</span> : <>
                {
                    caseList?.map((item, index) => {
                        return (
                            <MenuItem key={"case" + index} className='menu-items'>
                                <div className='info-col'>
                                    <div className='file-info'>
                                        <span className='file-name'>#{item.eapx_file_number}</span>
                                    </div>
                                    <div className='file-info'>
                                        <span className='last-modify-date'>{item.first_name},&nbsp;</span>
                                        <span className='last-modify-date'>{item.company_name}</span>
                                    </div>
                                </div>
                                <button className='claim-button'
                                    onClick={() => handleClaimCase(item.eapx_file_number)}>
                                    {"Claim"}
                                </button>
                            </MenuItem>)
                    })
                }</>
            }
        </div>
    )
}

export default CaseList