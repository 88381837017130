import React from "react";
import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import AlogiliaSearch from "./ProviderNetworkData";

const index: React.FC = () => {
  return (
      <FixedHeaderUserLayout children={<AlogiliaSearch />} />
  );
};

export default index;
