import React, { useState, useEffect, useCallback } from "react";
import { connectHits } from "react-instantsearch-dom";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { TextField, MenuItem, Select } from "@mui/material";
import "./ProviderNetwork.scss";
import Loader from "../../common/Loader/Loader";
import { useNavigate } from "react-router-dom";

interface HitObject {
  objectID: string;
  [key: string]: any;
}

interface CustomHitsProps {
  hits: HitObject[];
  indexName: string;
}

interface Row {
  id: string;
  [key: string]: any;
}

const CustomHits: React.FC<CustomHitsProps> = ({ hits, indexName }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedValues, setSelectedValues] = useState<{
    [key: string]: string;
  }>({});
  const navigate = useNavigate();

  const handleSelect = useCallback(
    (
      e: React.ChangeEvent<{ value: unknown }>,
      params: GridRenderCellParams
    ) => {
      const newValue = e.target.value as string;
      const rowId = params.id.toString();
      setSelectedValues((prev) => ({ ...prev, [rowId]: newValue }));
      const rowData = params.row;
      navigate("/send-refferal", { state: { rowData, indexName } });
    },
    []
  );

  useEffect(() => {
    setLoading(true);
    if (hits?.length === 0) {
      return;
    }

    const fields = Object.keys(hits[0]).filter((key) => !key.startsWith("_"));

    const rows: Row[] = hits.map((hit) => {
      const row: Row = { id: hit.objectID };
      fields.forEach((key) => {
        row[key === "objectID" ? "id" : key] = hit[key] || "-";
      });
      return row;
    });

    const nonEmptyRows = rows.filter((row) =>
      fields.some((key) => row[key === "objectID" ? "id" : key] !== "-")
    );

    if (nonEmptyRows.length === 0) {
      return;
    }

    const dynamicColumns: GridColDef[] = fields.map((key) => ({
      field: key === "objectID" ? "id" : key,
      headerName: key
        .replace(/_/g, " ")
        .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
      minWidth: 150,
      maxWidth:250
    }));

    dynamicColumns.push({
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      maxWidth: 250,
      renderCell: (params: GridRenderCellParams) => (
        <Select
          value={selectedValues[params.id.toString()] || ""}
          displayEmpty
          onChange={(e) =>
            handleSelect(e as React.ChangeEvent<{ value: unknown }>, params)
          }
        >
          <MenuItem value="" disabled>
            Select
          </MenuItem>
          <MenuItem value="Send Referral">Send Referral</MenuItem>
        </Select>
      ),
    });

    setColumns(dynamicColumns);

    setFilteredRows(
      nonEmptyRows.filter((row) =>
        Object.values(row).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    );

    setLoading(false);
  }, [hits, searchTerm]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="provider-network-page ">
      {loading ? (
        <Loader />
      ) : (
        <div className="loader-div">
          {!filteredRows?.length ? (
            <span className="loader-div">No data found</span>
          ) : (
            <div className="page-content">
              <TextField
                className="text-field"
                label="Search"
                variant="outlined"
                fullWidth
                size="small"
                value={searchTerm}
                onChange={handleSearch}
              />
              <div className="table-div">
                <DataGrid
                  rows={filteredRows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default connectHits<HitObject>(CustomHits);
