import React from "react";
import AppRouter from "./routes/AppRouter";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'


const App: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AppRouter />
    </LocalizationProvider>
  );
};

export default App;
