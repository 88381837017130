import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import ServicesCheck from "./ServicesCheck";

const index = () => {
  return (
    <div>
      <FixedHeaderUserLayout
        children={<ServicesCheck />}
        hideMyAccButton={true}
      />
    </div>
  );
};

export default index;
