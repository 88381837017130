import React, { useState, useContext } from "react";
import { login, storageKeys } from "../../../utils/constants";
import globalContext from "../../../context/globalContext";
import { logIn } from "../../../utils/ApiClient";
import { useNavigate, useLocation } from "react-router-dom";
import "./login.scss";

const Login: React.FC = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  //Button Disable
  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { globalErrorHandler }: any = useContext(globalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const redirectURL: any = searchParam.get("redirect_url");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setError(false);
    setDisabled(false);
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNavigation = () => {
    const oid: any = localStorage.getItem(storageKeys.ORGANIZATION_ID);
    const queryString = new URLSearchParams({
      oid: oid,
    }).toString();
    const newUrl = oid ? `/?${queryString}` : "/";
    navigate(newUrl);
  };

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

  const resetErrors = (): void => {
    setError(false);
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleSubmit = async (): Promise<any> => {
    resetErrors();
    setDisabled(true);

    if (!formData.email.trim()) {
      setError(true);
      setErrorMessage("Please provide an email to login.");
    } else if (!emailRegex.test(formData.email)) {
      setError(true);
      setErrorMessage("Please enter a valid email address.");
    } else {
      const emailWithRedireactURL = {
        email: formData.email,
        redirect_url: redirectURL,
      };
      const emailObj = {
        email: formData.email,
      };
      try {
        const credential = redirectURL ? emailWithRedireactURL : emailObj;
        const resolve: any = await logIn(credential);
        setSuccessMessage(resolve.data.message); // Assuming the success message is returned from the backend
        setDisabled(false);
      } catch (error: any) {
        globalErrorHandler(error);
      }
    }
  };

  return (
    <div className="login-page page-center-container margin-content">
      <span className="subtitle">{login.SUBTITLE}</span>
      <span className="title">{login.TITLE}</span>

      <form className="signup-form">
        <div className="input-div">
          <label>{login.EMAIL_ADDRESS}</label>
          <input
            type="email"
            name="email"
            className="signup-page-email-input"
            placeholder={login.EMAIL_ADDRESS_PLACEHOLDER}
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        {successMessage && <div className="success">{successMessage}</div>}
        {error && <div className="error">{errorMessage}</div>}
        <button
          onClick={handleSubmit}
          className={
            disabled ? "large-green-button-disabled" : "large-green-button"
          }
          disabled={disabled}
        >
          {login.BUTTON}
        </button>
      </form>
      <span className="nav-link nav-margin" onClick={handleNavigation}>
        {login.SIGN_UP_LINK}
      </span>
    </div>
  );
};

export default Login;
