import React from "react";
import "./EmergencyDisclaimer.css";

interface Props {
  phoneNumber: string;
}

export const EmergencyDisclaimer: React.FC<Props> = ({ phoneNumber }) => {
  return (
    <div className="emergency-disclaimer-component">
      If you are in crisis, please call your EAP at {phoneNumber}.
    </div>
  );
};
