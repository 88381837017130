import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import ProviderNetworks from "./ProviderNetworks";

const index = () => {
  return (
      <FixedHeaderUserLayout children={<ProviderNetworks />} />
  );
};

export default index;
