import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import BackButtonBlock from "../../../routes/BackButtonBlock";
import SupportRequest from "./SupportRequest";

const index = () => {
  return (
    <div>
      <BackButtonBlock />
      <FixedHeaderUserLayout children={<SupportRequest />} />
    </div>
  );
};

export default index;
