import { common } from '../../../../utils/constants';
import './SuccessMessage.scss'

const SuccessMessage = () => {
  return (
    <div className="success-message-page margin-content">
      <span className="title">{common.SUCCESS_MESSAGE}</span>
    </div>
  );
};

export default SuccessMessage;
