import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { common, storageKeys } from "../../../../utils/constants";
import { getQuestionsByRequestId } from "../../../../utils/workLifeData";
import { sendWorklifeRequestData } from "../../../../utils/ApiClient";
import globalContext from "../../../../context/globalContext";
import Loader from "../../../common/Loader/Loader";
import './RequestForm.scss'

interface Question {
  text: string;
  id: string;
  type: string;
  options?: string[];
  required? : boolean
}

const RequestForm: React.FC = () => {
  const location = useLocation();
  const requestId = location.state;
  const navigate = useNavigate();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [title, setTitle] = useState("");
  const [answers, setAnswers] = useState<any>({});
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { globalErrorHandler }: any = useContext(globalContext);

  useEffect(() => {
    const response: any = getQuestionsByRequestId(requestId);
    setTitle(response.requestName);
    setQuestions(response.questions);
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    questionKey: string
  ): void => {
    const { type, value } = e.target;
    if (type === "checkbox" && e.target instanceof HTMLInputElement) {
      const { checked } = e.target;
      const currentAnswers = answers[questionKey] || [];
      const updatedAnswers = checked
        ? [...currentAnswers, value]
        : currentAnswers.filter((answer: string) => answer !== value);

      setAnswers({
        ...answers,
        [questionKey]: updatedAnswers,
      });
    } else {
      setAnswers({
        ...answers,
        [questionKey]: value,
      });
    }
    setError("");
  };

  const validateFields = (): boolean => {
    for (const question of questions) {
      if (question.required && !answers[question.text]) {
        setError(`${question.text} is required.`);
        return false;
      }
    }
    setError("");
    return true;
  };

  const handleClick = async () => {
    if (validateFields()) {
      const requestData = {
        category: localStorage.getItem(storageKeys.CATEGORY),
        subCategory: localStorage.getItem(storageKeys.SUBCATEGORY)
          ? localStorage.getItem(storageKeys.SUBCATEGORY)
          : null,
        requestName: title,
        answers: answers,
      };
      try {
        setLoading(true);
        await sendWorklifeRequestData(requestData);
        setLoading(false);
        navigate("/success-message");
      } catch (error) {
        setAnswers("");
        setLoading(false);
        globalErrorHandler(error);
      }
    }
  };

  return (
    <div className="request-form-page">
      {loading ? (
        <Loader />
      ) : (
        <div className="page-container">
          <div className="form-container responsive-form">
            <span className="subtitle">{title}</span>
            {questions?.map((question: any) => {
              return (
                <div key={question.id} className="form-grid">
                  <div className="question-label">{question.text}</div>
                  {["text", "number"].includes(question.type) && (
                    <input
                      type={question.type}
                      value={answers[question.text] || ""}
                      onChange={(e) => handleChange(e, question.text)}
                      required
                    />
                  )}
                  {question.type === "radio" && (
                    <div className="radio-group">
                      {question.options?.map(
                        (option: string, index: number) => (
                          <label key={index} className="radio-inline">
                            <input
                              type="radio"
                              name={question.text}
                              value={option}
                              checked={answers[question.text] === option}
                              onChange={(e) => handleChange(e, question.text)}
                              required
                            />
                            {option}
                          </label>
                        )
                      )}
                    </div>
                  )}
                  {question.type === "checkbox" && (
                    <div className="checkbox-group">
                      {question.options?.map((option: string, index: number) => (
                        <label key={index} className="checkbox-inline">
                          <input
                            type="checkbox"
                            name={question.text}
                            value={option}
                            checked={answers[question.text]?.includes(option) || false}
                            onChange={(e) => handleChange(e, question.text)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  )}

                </div>
              );
            })}
          </div>
          {error && <div className="error">{error}</div>}
          <button
            className="question-button-yes margin-content"
            onClick={handleClick}
          >
            {common.SUBMIT}
          </button>
        </div>
      )}
    </div>
  );
};

export default RequestForm;
