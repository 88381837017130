import { common } from "../../../utils/constants";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <div className="footer-component">
      <Link className="footer" to="/privacy-policy">
        {common.FOOTER}
      </Link>

      <Link className="footer" to="/terms-of-service">
       {common.TERMS_OF_SERVICE}
      </Link>
    </div>
  );
};

export default Footer;
