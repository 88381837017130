export const headerText = {
  TITLE: "Benefits Available to you",
  MENUITEM1: "Personal Coaching",
  MENUITEM2: "Short-term Counseling",
  MENUITEM3: "Work-life Request",
  MENUITEM4: "Get Answers",
  LOGIN: "Log in",
  MENU: [
    "Personal Coaching",
    "Short-term Counseling",
    "Work-life Request",
    "Get Answers",
  ],
};

export const service1 = {
  title: "Personal Coaching",
  summary:
    "Chat with a personal coach and learn new strategies for dealing with stress, personal relationships, and tough transitions.",
  buttonText: "Connect with a Coach",
};
export const service2 = {
  title: "Short-term Counseling",
  summary:
    "Get hands-on help through life's biggest challenges such as the loss of loved one, anxiety, loneliness or significant distress.",
  buttonText: "Schedule First Session",
};
export const service3 = {
  title: "Work-life Request",
  summary:
    "Learn and discuss practical strategies for dealing with daily life ease and professional transitions.",
  buttonText: "WorkLife Request",
};
export const service4 = {
  title: "Get Answers",
  summary:
    "Chat with a benefits specialist to learn more about the different benefits available to you.",
  buttonText: "Connect with a Specialist",
};

export const common = {
  MY_ACCOUNT: "My Account",
  FIRST_NAME: "First Name",
  FIRST_NAME_PLACEHOLDER: "e.g. John",
  LAST_NAME: "Last Name",
  LAST_NAME_PLACEHOLDER: "e.g. Dowry",
  CANCEL: "Cancel",
  CONFIRM: "Confirm",
  EXIT: "Exit",
  YES: "Yes",
  NO: "No",
  NEXT: "Next",
  COMPANY: "Company",
  MEMBER_TYPE: "Member Type",
  GENDER: "Gender",
  MARITAL_STATUS: "Marital Status",
  PHONE: "Phone (10 digits)",
  LEAVE_VOICE_MAIL: "OK to leave voicemail?",
  STREET_ADDRESS: "Street Address",
  APT_FLOOR: "Apt/Floor",
  CITY: "City",
  STATE: "State",
  ZIPCODE: "Zip Code",
  SUBMIT: "Submit",
  SEND: "Send",
  LOADING: "Loading, Please wait!",
  CONTCAT_SUPPORT: "Internal Application Error. Please Contact Support",
  ERROR_MESSAGE: "You already have an open text coaching case.",
  PAGE_NOT_FOUND: "404 - Page Not Found",
  FOOTER: "Privacy Policy",
  TERMS_OF_SERVICE: "Terms of Service",
  TEXT_COACHING: "Text Coaching",
  SELF_SCHEDULING: "Self Scheduling",
  WORKLIFE_REQUEST: "WorkLife Request",
  GET_ANSWERS: "Get Answers",
  COUNSELING_SUPPORT: "Counseling support",
  TEXT_COACHING_OPTION: "Benefits overview or explore support options",
  SUCCESS_MESSAGE: "Your request has been successfully submitted",
};

export const toastMessage = {
  LOGIN_MESSAGE: "Please Log in again",
  LOG_OUT: "Logout failed. Please try again.",
};

export const home = {
  SUBTITLE: "We are here to help",
  TITLE: "Coaching and Counseling Support",
  BUTTON1: "Connect with a Coach",
  BUTTON2: "Schedule Counseling",
  BUTTON3: "WorkLife Request",
  NO_SERVICE: "No active services",
};

export const signup = {
  SUBTITLE: "Your privacy is our priority",
  TITLE: "Let’s start with a few basic details about you",
  FIRST_NAME: "First Name",
  FIRST_NAME_PLACEHOLDER: "e.g. John",
  LAST_NAME: "Last Name",
  LAST_NAME_PLACEHOLDER: "e.g. Dowry",
  EMAIL_ADDRESS: "Email Address",
  EMAIL_ADDRESS_PLACEHOLDER: "Please enter a valid address",
  BUTTON: "Sign Up",
  LOGIN_TEXT: "Returning user? Click here to log in",
  LOGIN_TEXT1: "Already have an account?",
  LOGIN_TEXT2: "Log in Here",
  EMAIL_SUBTITLE:
    "This email will be used to send you notifications regarding your messages.",
  CHECKBOX_LABEL: "By checking this box you agree to our",
  TERMS_OF_SERVICE: "Terms of Service",
  ADD_CUSTOMER: "Add customer",
};

export const login = {
  SUBTITLE: "Enter your email to Login",
  TITLE: "Enter your email to login",
  EMAIL_ADDRESS: "Email Address",
  EMAIL_ADDRESS_PLACEHOLDER: "Please enter a valid address",
  BUTTON: "Login",
  SIGN_UP_LINK: "Don't have an account? Click here to sign up",
};

export const ageGate = {
  SUBTITLE: "Let’s get to know you",
  QUESTION: "When is your birthday?",
  ERROR: "Please select a valid birthdate.",
};

export const accountInfo = {
  TITLE: "Coaching Conversations",
  NO_CONVERSATIONS: "No Conversations",
  CONVERSATIONS: "Conversations",
  REFFERALS: "Refferals",
};

export const questions = {
  EXP_SUBTITLE: "Please answer a few Questions",
  QUESTION_ONE: "Are you experiencing any thoughts of hurting yourself?",
  QUESTION_TWO: "Are you experiencing any thoughts of hurting others?",
  QUESTION_THREE:
    "Are you seeking support for substance abuse related concern?",
  HELP_US_TITLE: "Help us find the best provider for you",
};

export const supportRequest = {
  SUBTITLE: "What’s on your mind?",
  TITLE: "In what area were you looking to receive support?",
  ERROR_SHOW: "Please select at least one option for which you seek support.",
  STATIC_MESSAGE: "Hi. I am reaching out to talk about ",
  OPTIONS: [
    "Support for daily challenges (i.e. time management, communication, skill building)",
    "Exploring wellness goals and next steps for achieving them.",
  ],
};

export const callPage = {
  SUBTITLE: "Our Experts are a Call Away",
  TITLE: "Please give us a call",
  NUMBER: "800-833-8707",
};

export const meetCoach = {
  SUBTITLE: "meet your Coach",
  TITLE:
    "You have been connected to Brittany. She will respond to you soon. You will be notified by email once she responds.",
  COACH_NAME: "Brittany",
  DESIGNATION: "Certified Professional Coach",
  DESCRIPTION:
    "Brittany has worked in the mental health field for nearly a decade as a Licensed Marriage & Family Therapist. She has experience in coaching clinically complex individuals. As a coach, Brittany values assisting clients in unearthing the inner power that inspires them to live authentically as their best selves. Side by side, she works as a teammate to collaborate on specific goals while implementing an action-oriented approach. As the ultimate counterpart, she looks forward to assisting clients on their journey to continued wellness.",
  RESPONSE:
    "Please note the coach's hours are 12-8PM EST. All messages are responded to within 4 hours, during business hours and you will get an email notification for new messages.",
};

export const selfScheduleQuestion = {
  PageOne: {
    TITLE:
      "Over the last two weeks, how often have you been bothered by any of the following problems?",
    QUESTION_1: "Little interest or pleasure in doing things",
    QUESTION_2: "Feeling down, depressed, or hopeless",
    QUESTION_3: "Trouble falling or staying asleep, or sleeping too much",
    QUESTION_4: "Feeling tired or having little energy",
    QUESTION_5: "Poor appetite or overeating",
    QUESTION_6:
      "Feeling bad about yourself - or that you are a failure or have let yourself or your family down",
    QUESTION_7:
      "Trouble concentrating on things, such as reading the newspaper or watching television",
    QUESTION_8:
      "Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual",
    QUESTION_9:
      "Thoughts that you would be better off dead, or of hurting yourself",
  },
  PageTwo: {
    QUESTION_1: "Feeling nervous, anxious, or on edge",
    QUESTION_2: "Not being able to stop or control worrying",
    QUESTION_3: "Trouble relaxing",
    QUESTION_4: "Being so restless that it is hard to sit still",
    QUESTION_5: "Becoming easily annoyed or irritable",
    QUESTION_6: "Feeling afraid, as if something awful might happen",
  },
  Options: {
    OPTION1: "Not at all",
    OPTION2: "Several days",
    OPTION3: "More than half the days",
    OPTION4: "Nearly every day",
  },
  Additonal_Question: {
    QUESTION1:
      "If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?",
    QUESTION2:
      "If you checked any problems, how difficult have they made it for you to do your work, take care of things at home, or get along with other people?",
    Options: [
      "Not difficult at all",
      "Somewhat difficult",
      "Very difficult",
      "Extremely difficult",
    ],
  },
};

export const GeneralQuestions = {
  QUESTION1: "How did you hear about the service?",
  QUESTION2: "In what area can we be of support?",
  ERROR: "Please answer above questions",
  OPTION1: [
    "Used Service Before",
    "Printed Materials from HR",
    "Digital Materials from HR",
    "Recommended by HR/Manager/Supervisor",
    "Recommended by Co-Worker/Friend/Family Member",
    "Benefit/Health Fair",
    "Employee Orientation/Training",
    "Benefit Website",
  ],
  OPTION2: [
    "Grief/Loss",
    "Relationship/Family Concerns",
    "Adjustment/Change",
    "Stress",
    "Addiction Concerns",
    "Anxiety",
    "Depression",
    "Emotion Regulation",
    "Self Growth",
  ],
};

export const chat = {
  ACTIVE_LABEL: "Type here...",
  DISABLELABEL:
    "This conversion as ended. Please start a new conversation from your accounts page",
};

export const WorkLifeCategory = {
  CATEGORY_TITLE: "Choose one of the category for Work Life support you want",
};

// Constants for Local Storage Keys
export const LS_PHONE_NUMBER = "phoneNumber";

export interface EapxParmas {
  company: string;
  email: any;
  first_name: string;
  last_name: string;
  dob: string;
  age: number;
  gender: string;
  phone_number: number;
  street_address: string;
  state: string;
  zip_code: string;
  marital_status: string;
  client_type: string;
  question_1: string;
  leave_voicemail: boolean;
  city: string;
}

export interface FormFields {
  company: string;
  member_type: string;
  gender: string;
  marital_status: string;
  phone: string;
  leave_mail: string;
  street_address: string;
  apt_floor: string;
  city: string;
  state: string;
  zipcode: string;
}

export const storageKeys = {
  USER_ID: "user-id",
  TOKEN: "token",
  EMAIL: "email",
  FLOW: "flow",
  FIRST_NAME: "first-name",
  LAST_NAME: "last-name",
  SESSION_TOKEN: "session-token",
  COMPANY: "company",
  AGE: "age",
  ORGANIZATION_ID: "organization-id",
  SESSION_EXPIRY: "session-expiry",
  TICKET_ID: "ticket-id",
  CHANNEL_NAME: "channel-name",
  CHANNEL_URL: "channel-url",
  ACTIVE_TICKET: "active-ticket",
  HELP_FORM: "help-form",
  BIRTH_DATE: "birth-date",
  SELF_SECHULE_QUESTION: "self-schedule-question",
  TEXT_COACHNG_QUESTION: "text-coach-question",
  AVAILABLE_SERVICE: "available-service",
  RESGISTRATION_BLOCK: "isRegistrationBlocked",
  PROGRESS_PERCENTAGE: "progress-percentage",
  SS_QUESTION_1: "ss-question-1",
  SS_QUESTION_2: "ss-question-2",
  GENERAL_SCHEDULE_QUESTION: "general-schedule-question-1",
  CATEGORY: "category",
  SUBCATEGORY: "subcategory",
  USER_TYPE: "user-type",
  ID: "id",
};

export const provoiderNetworks = [
  { name: "2024 EAP Providers", indexName: "EAPProvider" },
  { name: "2024 VIP Providers", indexName: "VIPProvider" },
  { name: "Substance-Abuse", indexName: "SubstanceAbuse" },
  { name: "Psychiatrists", indexName: "Psychiatrist" },
  { name: "Therapists-EAP|Affiliates", indexName: "TherapistEAPAffiliates" },
  { name: "Clinics&GroupPractices", indexName: "ClinicAndGroupPractices" },
  { name: "Therapists-EAP|Provider Pull", indexName: "TherapistEAPProvider" },
  { name: "VIP Talent", indexName: "VIPTalent" },
  {
    name: "Therapists-Direct & Affiliates",
    indexName: "TherapistDirectAndAffialiates",
  },
  { name: "SAP-Qualified", indexName: "EAPProvider" },
  { name: "WayForward CM", indexName: "EAPProvider" },
  { name: "DV Resources", indexName: "DVResource" },
  { name: "InsuranceWebSites", indexName: "EAPProvider" },
];

export const dashBoard = {
  TAB1: "All Refferals",
  TAB2: "My Refferals",
  TAB3: "Provider Networks",
  TAB4: "EAP-Case files",
  TITLE: "Refferals",
};
export const userType = {
  CUSTOMER: "customer",
  PRT_USER: "prt_user",
};

export const caseType = [{ name: "My Cases", value: "assigned_to_team,provider_search_in_progress" }, { name: "Closed", value: "provider_accepted_case" }]

export const EapxCaseStatus = [
  { name: 'P - Assigned to Team Member', value: "assigned_to_team" },
  { name: 'P - Provider Search in Progress', value: "provider_search_in_progress" },
  { name: 'P - Provider Accepted Case', value: "provider_accepted_case" },
]

export const EapxSpecialist = [
  "Assigned Specialist",
  "Aletha Cannistra",
  "Alison Milonakis",
  "Cheryl Cohen",
  "Eena Reyes",
  "Jackie Alleva",
  "Jaclyn Reynolds",
  "Jenna Kaufmann",
  "Sarette Jordan"
]

export const providerStatus = [{ name: "Request Sent", value: "request_sent" }, { name: "Provider Declined", value: "provider_declined" }, { name: "Provider Approved", value: "provider_approved" }]

export const caseStatusChangeDialog = {
  title: "CHANGING CASE STATUS",
  content: `Please confirm that you want to change the case status to "P - Provider accepted case" without assigning a designated provider. Note that a confirmation email will be sent to the specialist without the provider information attached.`
}

export const providerAssignDialog = {
  title: "CHANGING CASE STATUS",
  content: `Please confirm that you want to assign this case to designated provider without approved provider. Note that a confirmation email will be sent to the specialist without the provider information attached.`,
  confirmLabel: "Confirm"
}

export const deleteProviderDialog = {
  title: "Are you sure want to delete provider?",
  content: "",
  confirmLabel: "Delete"
}

export const assigningProviderDialog = {
  title : "ASSIGNING A PROVIDER",
  content : "Please confirm that you are assigning a provider to this case. A confirmation email will be sent to the specialist with the selected provider, and this case will then be marked as closed.",
  confirmLabel : "Confirm"
}