import { useNavigate } from "react-router-dom";
import { provoiderNetworks } from "../../../utils/constants";
import "./ProviderNetworks.scss";

const ProviderNetworks = () => {
  const navigate = useNavigate();
  const handleNavigation = (indexName: string) => {
    navigate("/provider-network-info", { state: indexName });
  };
  return (
    <div className="provider-networks-page page-content">
      <span className="title">{"Provider Networks"}</span>
      <div className="grid-container">
        {provoiderNetworks?.map((item: any, index: number) => {
          return (
            <div
              className="card"
              key={index}
              onClick={() => handleNavigation(item.indexName)}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProviderNetworks;
