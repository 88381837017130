import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import PRTDashboardHome from "./PRTDashboardHome";

const index: React.FC = () => {
  return (
    <FixedHeaderUserLayout children={<PRTDashboardHome />} />
  );
};

export default index;
