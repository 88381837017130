import { useContext, useEffect, useState } from "react";
import SideTabs from "../../common/SideTabs/SideTabs";
import { dashBoard, storageKeys } from "../../../utils/constants";
import { getAllRefferals, getMyRefferals } from "../../../utils/ApiClient";
import Loader from "../../common/Loader/Loader";
import globalContext from "../../../context/globalContext";
import "./DashBoard.scss";
import RefferalList from "../../common/RefferalList/RefferalList";

const DashBoard = () => {
  const [activeTab, setActiveTab] = useState<string>(dashBoard.TAB1);
  const [refferals, setRefferals] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { globalErrorHandler }: any = useContext(globalContext);
  const tabs = [dashBoard.TAB1, dashBoard.TAB2, dashBoard.TAB3, dashBoard.TAB4];

  const fetchData = async (fetchFunction: () => Promise<any>) => {
    setLoading(true);
    try {
      const response = await fetchFunction();
      setRefferals(response.data);
    } catch (error) {
      globalErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const getRefferals = () => getAllRefferals();

  const getMyRefferal = () => {
    const id: any = localStorage.getItem(storageKeys.ID);
    return getMyRefferals(id);
  };

  useEffect(() => {
    const fetchFunction =
      activeTab === dashBoard.TAB1 ? getRefferals : getMyRefferal;
    fetchData(fetchFunction);
  }, [activeTab]);

  return (
    <div className="dashboard-page">
      {loading ? (
        <Loader />
      ) : (
        <div className="dashbord-grid">
          <SideTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <RefferalList refferals={refferals} title={dashBoard.TITLE} />
        </div>
      )}
    </div>
  );
};

export default DashBoard;
