import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import GeneralSelfSchedulingQuestions from "./GeneralSelfSchedulingQuestions";

const index: React.FC = () => {
  return (
      <FixedHeaderUserLayout children={<GeneralSelfSchedulingQuestions />} />
  );
};

export default index;
