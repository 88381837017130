import { useEffect } from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="privacy-policy-page">
      <div className="policy-title">
        <span>{"Notice of Privacy Practices"}</span>
      </div>
      <div className="policy-subtitle">{"OUR LEGAL DUTY"}</div>
      <span className="text-content">
        {
          "We conduct business in accordance with the guidelines established by the Federal Health Insurance Portability and Accountability Act (HIPAA).   This notice describes how protected health information (medical information) about you may be used and disclosed and how you can gain access to this information.   Please review it carefully and feel free to ask questions."
        }
      </span>
      <span className="text-content">
        {
          "We are required by applicable federal and state law to maintain the privacy of your health information.  We are also required to give you this Notice about our privacy practices, our legal duties, and your rights concerning your health information."
        }
      </span>
      <div className="policy-subtitle">
        {"YOUR PROTECTED HEALTH INFORMATION"}
      </div>

      <span className="bold-text">{"Uses and Disclosures"}</span>
      <span className="text-content">
        {
          "The EAP provides a safe and secure place to address your personal issues and concerns.  We will help to assess your situation and develop the most appropriate plan of action with you.  The plan may include counseling and/or a referral to an appropriate professional or program to help resolve your problem.  We may use or disclose your health information to a counselor, behavioral health or medical care professional for purposes of assessing your counseling needs, making appropriate referrals and ensuring the continuity of your care."
        }
      </span>
      <span className="text-content">
        {
          "There is no cost to you for services provided by the EAP’s Clinical Department, however we do not cover the cost of services to which you may be referred.  We attempt to maintain up-to-date information on your health insurance coverage so that we can refer you to providers covered by your plan, and will work with you to verify your insurance benefit coverage.  We may use or disclose your health information to facilitate payment for services."
        }
      </span>
      <span className="text-content">
        {
          "Finally, the EAP may use and disclose your health information in connection with our operations.  This includes quality assurance and improvement activities and reviewing the performance of our counselors."
        }
      </span>

      <span className="bold-text">{"Appointment Reminders"}</span>
      <span className="text-content">
        {
          "We may use or disclose your health information to provide you with appointment reminders (such as voicemail messages, postcards or letters)."
        }
      </span>

      <span className="bold-text">{"Requesting Restrictions"}</span>
      <span className="text-content">
        {
          "You may initiate restrictions on certain uses and disclosures of your protected health information as described in #1.  The EAP will do everything possible to reach a reasonable consensus with you in regard to these restrictions.  However, we may deny service if it deems such restrictions would significantly preclude our ability to provide you with professional services."
        }
      </span>

      <span className="bold-text">{"Your Written Authorization"}</span>
      <span className="text-content">
        {
          "We will not release your private health information to other organizations not involved with your treatment, payment and health care operations; such as your employer, schools, and some types of community services, without your written authorization. If you have been formally referred to the Assistance Program by your company due to workplace performance or behavioral issues, you will be asked to sign an authorization giving us permission to report attendance and compliance with the program to your employer."
        }
      </span>
      <span className="text-content">
        {"You have a right to revoke an authorization in writing."}
      </span>

      <span className="bold-text">{"Accounting of Disclosures"}</span>
      <span className="text-content">
        {
          "You have a right to an accounting of some disclosures of your protected health information to third parties.  This does not include disclosures that you authorize or disclosures that occur in the context of providing you routine services."
        }
      </span>

      <span className="bold-text">{"Required Disclosures"}</span>
      <span className="text-content">
        {
          "There are four limitations to confidentiality, which are beyond our control.  They are as follows:"
        }
      </span>
      <span className="text-content">
        {
          "If we learn of any alleged child abuse or neglect or abuse of the elderly, we are required by law, as mental health professionals, to report this to the State Child Abuse Registry or State Adult Protective Services.  This information will not be shared with your company."
        }
      </span>
      <span className="text-content">
        {
          "If in our judgement a client is dangerous to himself/herself or others, we may need to break confidentiality in order to assure a client’s safety or the safety of others."
        }
      </span>
      <span className="text-content">
        {
          "If a client reveals to us information relating to the contemplation of a criminal act, we may need to break confidentiality to prevent the criminal act."
        }
      </span>
      <span className="text-content">
        {
          "If we are required to present records and/or a counselor to comply with a court order."
        }
      </span>

      <span className="bold-text">{"Questions and Complaints"}</span>
      <span className="text-content">
        {
          "The EAP has appointed a privacy officer who monitors the confidentiality of your protected health information.  We rely on your input so that we may continually ensure that we are protecting your privacy and effectively communicating your privacy rights.  If you have a question, wish to provide feedback or initiate requests related to items 1-6 above, you may contact the privacy officer at 212-686-6827."
        }
      </span>
      <span className="text-content">
        {
          "If you believe that your privacy rights have been violated you may make a complaint to our privacy officer or the Federal Office of the Civil Rights Privacy Unit at 866-627-7748.  The EAP will not take action against you for doing so."
        }
      </span>

      <span className="bold-text">{"E-mail"}</span>
      <span className="text-content">
        {
          "We not recommend that you communicate with us through e-mail other than for routine matters such as confirmation of appointments.  If you choose to do so, we cannot guarantee that it can protect any health information contained in such e-mail."
        }
      </span>

      <span className="bold-text">{"Alternative Communication"}</span>
      <span className="text-content">
        {
          "You have the right to request that we communicate with you about your health information by alternative means or at alternative locations.  You must make your request in writing.  Your request must specify the alternative means or location."
        }
      </span>

      <span className="bold-text">{"Revised Notices"}</span>
      <span className="text-content">
        {
          "CCA reserves the right to make changes to its privacy practices and will provide you with a revised notice relevant to your protected health information as long as you are an active client of the EAP."
        }
      </span>

      <span className="bold-text">{"Right to Paper Copy"}</span>
      <span className="text-content">
        {"If you wish, you may request a copy to be sent via unsecured E-mail."}
      </span>

      <p className="text-content">
        {
          "By signing below you are acknowledging receipt of our privacy practices and providing your consent for the EAP to assist you as described above.  If you choose not to sign, we will make note of your refusal and may ask you to sign specific consent forms in order to engage in the activities needed to facilitate your care."
        }
      </p>
    </div>
  );
};

export default PrivacyPolicy;
