import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import DashBoard from "./DashBoard";

const index = () => {
  return (
      <FixedHeaderUserLayout children={<DashBoard />} />
  );
};

export default index;
