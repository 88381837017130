import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import Register from "./Register";
import AuthenticationCheck from "../../../routes/AuthencticationCheck";
import ServiceCheck from "../../../routes/ServiceCheck";

const index = () => {
  return (
    <div>
      <ServiceCheck />
      {<AuthenticationCheck />}
      <FixedHeaderUserLayout children={<Register />} hideMyAccButton={true} />
    </div>
  );
};

export default index;
