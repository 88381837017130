const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

export const validate = (formData: any) => {
  if (!formData.firstname) {
    return "First name is required.";
  }
  if (!formData.lastname) {
    return "Last name is required.";
  }
  if (!formData.email) {
    return "Email address is required.";
  } else if (!emailRegex.test(formData.email)) {
    return "Please enter a valid email address.";
  }
  return false;
};
