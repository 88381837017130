import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import MeetYourCoach from "./MeetYourCoach";

const index: React.FC = () => {
  return (
    <FixedHeaderUserLayout children={<MeetYourCoach />} />
  );
};

export default index;
