import React, { useContext, useEffect, useRef, useState } from "react";
import { common, storageKeys } from "../../../utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { getOrganizationDetail } from "../../../utils/ApiClient";
import Loader from "../../common/Loader/Loader";
import globalContext from "../../../context/globalContext";
import { getServices } from "../../../utils/getService";
import ServiceCards from "../../common/ServiceComponent/ServiceComponent";
import { useNavigationHandler } from "../../../utils/useNavigationHandler";
import './HomePage.scss'

const Homepage: React.FC = () => {
  const navigate = useNavigate();
  const { flowNavigation } = useNavigationHandler();
  const [error, setError] = useState<boolean>(false);
  const {
    globalErrorHandler,
    setServiceType,
    serviceType,
    loader,
    setLoader,
  }: any = useContext(globalContext);
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const oId: any = searchParam.get("oid");
  const storageoId = localStorage.getItem(storageKeys.ORGANIZATION_ID);
  const organizationId = oId ? oId : storageoId;
  const effectRef = useRef(false);

  const getEAPXOrganizationDetail = async (oId: any): Promise<void> => {
    try {
      const response: any = await getOrganizationDetail(oId);
      localStorage.setItem(storageKeys.COMPANY, response.data.data.name);
      localStorage.setItem(storageKeys.ORGANIZATION_ID, response.data.data.id);
    } catch (error: any) {
      globalErrorHandler(error);
      if (error.response?.status === 404) {
        setError(true);
      } else {
        navigate("/invalid-link");
      }
    }
  };

  useEffect(() => {
    if (effectRef.current === false) {
      if (organizationId) {
        getEAPXOrganizationDetail(organizationId);
        getServices(organizationId, {
          globalErrorHandler,
          setServiceType,
          setLoader,
        });
      } else {
        navigate("/invalid-link");
      }
    }
    return () => {
      effectRef.current = true;
    };
  }, []);

  const handleNavigation = (flow?: string) => {
    const flowName = flow || "";
    flowNavigation(flowName);
  };

  return (
    <div className="home-page page-center-container">
      {error ? (
        <div className="loader-container">
          <span>{common.CONTCAT_SUPPORT}</span>
        </div>
      ) : (
        <>
          {loader ? (
            <Loader />
          ) : (
            <ServiceCards
              serviceType={serviceType}
              handleButtons={handleNavigation}
              isLandingPage={true}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Homepage;
