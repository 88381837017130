import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { storageKeys } from "../utils/constants";

const RedirectCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const isBlocked = localStorage.getItem(storageKeys.RESGISTRATION_BLOCK);
    if (isBlocked && currentPath !== "/call-page") {
      navigate("/call-page");
    }
  });

  return <></>;
};

export default RedirectCheck;
