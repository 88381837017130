import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import { selfScheduleQuestion } from "../../../utils/constants";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import Questions from "../../common/SelfScheduleQuestion/SelfScheduleQuestion";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RadioButtonAssessmentPage1 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
    if (!EnvironmentConfig.show_gad7_phq9) {
      navigate("/general-questions");
    }
  });

  const option = [
    selfScheduleQuestion.Options.OPTION1,
    selfScheduleQuestion.Options.OPTION2,
    selfScheduleQuestion.Options.OPTION3,
    selfScheduleQuestion.Options.OPTION4,
  ];
  const questions = [
    { question: selfScheduleQuestion.PageOne.QUESTION_1, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_2, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_3, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_4, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_5, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_6, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_7, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_8, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_9, options: option },
  ];
  const additionalQuestion = selfScheduleQuestion.Additonal_Question.QUESTION1;
  const additionalOptions = selfScheduleQuestion.Additonal_Question.Options;
  const targetPath = "/standardized-survey-two";
  return (
    <div>
      <FixedHeaderUserLayout
        children={
          <Questions
            option={option}
            questions={questions}
            additionalQuestion={additionalQuestion}
            additionalOptions={additionalOptions}
            targetPath={targetPath}
          />
        }
      />
    </div>
  );
};

export default RadioButtonAssessmentPage1;
