import React from "react";
import { callPage } from "../../../utils/constants";

const CallPage : React.FC = () => {
  return (
    <div className="page-center-container margin-content">
      <span className="subtitle">{callPage.SUBTITLE}</span>
      <span className="title">{callPage.TITLE}</span>
      <span className="title">{callPage.NUMBER}</span>
    </div>
  );
};

export default CallPage;
