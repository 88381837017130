import React, { useContext, useEffect, useState } from 'react';
import { caseType } from '../../../../utils/constants';
import { getFormatDateString, extractTimeFromString } from '../../../../utils/helperFunctions';
import { getEAPCaseFileData, getEAPCases, searchEapxCase } from '../../../../utils/ApiClient';
import globalContext from '../../../../context/globalContext';
import SearchIcon from '../../../assests/icons/search-icon.png';
import { Menu } from '@mui/material';
import CaseList from './CaseList';
import './EAPCasesSidebar.scss';
import Loader from '../../Loader/Loader';
import debounce from 'lodash/debounce';

interface EAPSideBarProps {
  cases: any[];
  activeCase: any;
  setActiveCase: (activeCase: any) => void;
  setActiveCaseInfo: (caseInfo: any) => void;
  setCases: (cases: any) => void;
  casesTypeName: string;
  setCasesTypeName: (name: string) => void;
  setCaseLoading: (loading: boolean) => void;
  setCaseClaimed: (claim: boolean) => void;
  setFileNo: (file: number) => void;
  setNoData: (data: boolean) => void;
}

const EAPCaseSideBar: React.FC<EAPSideBarProps> = ({
  cases,
  activeCase,
  setActiveCase,
  setActiveCaseInfo,
  setCases,
  setCasesTypeName,
  casesTypeName,
  setCaseLoading,
  setCaseClaimed,
  setFileNo,
  setNoData,
}) => {
  const { globalErrorHandler }: any = useContext(globalContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openList, setOpenList] = useState<boolean>(false);
  const [caseList, setCaseList] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false)
  const [noCase, setNoCase] = useState<boolean>(false)

  const getCaseData = async (id: any): Promise<void> => {
    setCaseLoading(true);
    try {
      const response = await getEAPCaseFileData(id);
      setActiveCaseInfo(response.data.data);
      setCaseLoading(false);
    } catch (error) {
      globalErrorHandler(error);
    }
  };

  const handleCaseType = async (case_type: any): Promise<void> => {
    if (casesTypeName === case_type.name) return
    setLoader(true);
    setCaseLoading(true)
    try {
      const response = await getEAPCases(case_type.value);
      if (!response.data.data.cases.length && response.data.data.first_case_detail === null) {
        setNoData(true);
        setCasesTypeName(case_type.name);
        setCases([])
        setActiveCase(0)
        setActiveCaseInfo(null)
      } else {
        setNoData(false)
        setCases(response.data.data.cases);
        setActiveCase(response.data.data.first_case_detail.id)
        setActiveCaseInfo(response.data.data.first_case_detail);
        setCasesTypeName(case_type.name);
      }
    } catch (error) {
      globalErrorHandler(error);
    } finally {
      setLoader(false);
      setCaseLoading(false)
    }
  };

  const handleCase = (id: any, fileNo: number) => {
    if (activeCase === id) return
    setFileNo(fileNo);
    setActiveCase(id);
    getCaseData(fileNo);
  };

  const searchCase = async (): Promise<void> => {
    if(!searchTerm) return
    setLoader(true);
    try {
      const response = await searchEapxCase(searchTerm);
      setCaseList(response.data.data.cases);
      setOpenList(true);
      setLoader(false);
      setSearchTerm("")
    } catch (error) {
      setLoader(false)
      setOpenList(true);
      setNoCase(true)
      globalErrorHandler(error);
      setTimeout(() => {
        setNoCase(false)
        setOpenList(false)
        setSearchTerm("")
      }, 5000)
    }
  };

  const handleClose = () => {
    setOpenList(false)
  }

  return (
    <div className='eap-cases-sidebar-component'>
      <div className='search-box' id='case-list' aria-haspopup="true" aria-controls={openList ? 'case-list' : undefined}
        aria-expanded={openList ? 'true' : undefined}>
        <input
          placeholder='Search'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <img src={SearchIcon} alt='search' className='search-icon' onClick={searchCase} />
      </div>
      {openList && (
        <Menu
          id="case-list"
          open={openList}
          onClose={handleClose}
          style={{ maxHeight: 400 }}
          MenuListProps={{
            'aria-labelledby': 'case-list',
          }}
        >
          <CaseList
            caseList={caseList}
            setCaseClaimed={setCaseClaimed}
            setCaseLoading={setCaseLoading}
            setSearchTerm={setSearchTerm}
            setOpenList={setOpenList}
            noCase={noCase}
            searchTerm={searchTerm}
          />
        </Menu>
      )}
      <div className='case-options'>
        {caseType.map((item: any, index: number) => (
          <span
            className={casesTypeName === item.name ? "active-case-type" : 'case-type'}
            key={"opt" + index}
            onClick={() => handleCaseType(item)}
          >
            {item.name}
          </span>
        ))}
      </div>
      <span className='case-title'>{"Case number"}</span>
      {
        loader ? <div className='loader-box'><Loader /></div> : <>
          {cases.length ? (
            <div className='case-list'>
              {cases.map((item: any, index: number) => {
                return (
                  <div
                    className={activeCase === item.eapx_case_uuid ? 'active-case-div' : 'case-div'}
                    key={"case" + index}
                    onClick={() => handleCase(item.eapx_case_uuid, item.eapx_file_number)}
                  >
                    <div className='info-section'>
                      <div className='section-one'>
                        <span className='case-id'>#{item.eapx_file_number?.toString()}</span>
                        <div className='identity-section'>
                          <span className='user-identity'>{item.first_name}, </span>
                          <span className='user-identity'>{item.company_name}</span>
                        </div>
                      </div>
                      <div className="date-time-section">
                        <span className='last-modified'>{getFormatDateString(item.eapx_last_modified).formattedDate}</span>
                        <span className='last-modified'>{extractTimeFromString(item.eapx_last_modified)}</span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className='no-cases'>{"No cases claimed"}</div>
          )} </>
      }
    </div>
  );
};

export default React.memo(EAPCaseSideBar);
