import React, { useState, useEffect } from 'react'
import { getFormatDateString, getAppointmentPreferences } from '../../../utils/helperFunctions'
import { EapxCaseStatus, EapxSpecialist } from '../../../utils/constants';
import CustomDropdownMenu from '../../common/DropdownMenu/DropdownWithConfirmationBar';

interface CaseDetailsProps {
    activeCaseInfo: any;
    caseStatus: string;
    personalInfo: any;
    presentCondition: string;
    customQuestion: any;
    setCaseStatus: (status: string) => void;
    activeCase: string;
    caseLoading: boolean;
    fileNo: any;
    additionalProviders?: any;
    setSpecialist: (specialist: string) => void;
    specialist: string;
    casesTypeName: string;
}

const CaseDetails: React.FC<CaseDetailsProps> = ({ activeCaseInfo, caseStatus, personalInfo, presentCondition,activeCase, customQuestion, setCaseStatus, caseLoading, fileNo, setSpecialist, specialist, casesTypeName, additionalProviders }) => {
    const [status, setStatus] = useState<string>("");
    const appointPreference = getAppointmentPreferences(customQuestion)
    const [successMessage, setSuccessMessage] = useState<string>("")

    useEffect(() => {
        setStatus(caseStatus)
    }, [caseStatus])

    useEffect(() => {
        if (successMessage) {
            setCaseStatus(status);
            setTimeout(() => {
                setSuccessMessage("")
            }, 5000)
        }
    }, [successMessage])

    return (
        <>{
            (activeCaseInfo && !caseLoading) && <React.Fragment>
                <div className='case-sub-section'>
                    <div className='case-staus-section'>
                        <div className='case-status-content'>
                            <span>Eapx Case: {activeCaseInfo?.id}</span>
                            <span>Case status: {caseStatus}</span>
                            <span>ICT Case Manager: N/A</span>
                            <span>Last modified: {getFormatDateString(activeCaseInfo?.last_modified).formattedDate}</span>
                        </div>
                        <div className='case-status-content'>
                            <CustomDropdownMenu
                                options={EapxCaseStatus}
                                name={"eapx_file_status"}
                                stateChange={setStatus}
                                state={status}
                                showButtons={true}
                                fileNo={fileNo}
                                showPopUp={true}
                                setSuccessMessage={setSuccessMessage}
                                setCaseStatus={setCaseStatus}
                                caseStatus={caseStatus}
                                casesTypeName={casesTypeName}
                                activeCase={activeCase}
                                additionalProviders={additionalProviders} 
                            />
                            <CustomDropdownMenu
                                options={EapxSpecialist}
                                name={"assigned_specialist"}
                                stateChange={setSpecialist}
                                state={specialist}
                                showButtons={true}
                                fileNo={fileNo}
                                showPopUp={true}
                                setSuccessMessage={setSuccessMessage}
                                casesTypeName={casesTypeName} 
                            />
                        </div>
                    </div>
                    {
                        successMessage && <span className='success success-msg-section'>{successMessage}</span>
                    }
                </div>
                <div className='case-sub-section'>
                    <span>First Name : {personalInfo?.first_name ? personalInfo?.first_name : "-"}</span>
                    <span>Gender : {personalInfo?.gender ? personalInfo?.gender : "-"}</span>
                    <span>State : {personalInfo?.state ? personalInfo?.state : "-"}</span>
                    <span>Zipcode : {personalInfo?.zipcode ? personalInfo?.zipcode : "-"}</span>
                </div>
                <div className='case-sub-section'>
                    <span>Client Company : {activeCaseInfo?.company_name ? activeCaseInfo.company_name : "-"} </span>
                    <span>Presenting condition : {JSON.stringify(presentCondition) ? JSON.stringify(presentCondition) : "-"} </span>
                    <span>Prefered modality : {activeCaseInfo?.preferred_modality ? activeCaseInfo?.preferred_modality : "-"} </span>
                    <span>Distance willing to travel : {customQuestion?.distance_willing_to_travel ? customQuestion?.distance_willing_to_travel : "-"} </span>
                    <span>Provider preferences: {customQuestion?.provider_preference ? customQuestion?.provider_preference : "-"} </span>
                    <span>Appointment date preference : {appointPreference ? `${appointPreference.dayPreference},  ${appointPreference.timePreference}` : "-"} </span>
                </div>
            </React.Fragment>
        }
        </>
    )
}

export default React.memo(CaseDetails)