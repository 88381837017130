import React from "react";
import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import SendRefferal from "./SendRefferal";

const index: React.FC = () => {
  return (
      <FixedHeaderUserLayout children={<SendRefferal />} />
  );
};

export default index;
