import React from 'react'
import { Dialog, DialogContent } from '@mui/material';
import './ConfirmDialogBox.scss'

interface DialogProps {
    title: string;
    content: string;
    confirmLabel: string;
    onConfirm: () => void;
    handleClose: () => void;
    openDialogBox: boolean;
}

const ConfirmDialogBox: React.FC<DialogProps> = ({ title, content, handleClose, openDialogBox, onConfirm, confirmLabel }) => {
    return (
        <Dialog onClose={handleClose} open={openDialogBox}>
            <DialogContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', columnGap: '10px' }}>
                <div className='changing-status-dialog-component'>
                    <span className='content-title'>{title}</span>
                    <p className='dialog-content'>{content}</p>
                    <div className='dialog-buttons'>
                        <button className='dialog-cancel-btn' onClick={handleClose}>
                            {"Cancel"}
                        </button>
                        <button className='assign-button' onClick={onConfirm}>
                            {confirmLabel}
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmDialogBox