import React, { useState, useEffect, useContext } from "react";
import "./Toast.scss";
import globalContext from "../../../context/globalContext";

type ToastProps = {
  duration?: number;
};

const Toast: React.FC<ToastProps> = ({ duration = 10000 }) => {
  const { globalError, setGlobalError }: any = useContext(globalContext);
  const [visible, setVisible] = useState(false);

  const handleToastClick = () => {
    setGlobalError("")
    setVisible(false);
  };

  useEffect(() => {
    if (globalError) {
      setVisible(true);
    }
    const timeout = setTimeout(() => {
      setGlobalError("");
      setVisible(false);
    }, duration);
    return () => clearTimeout(timeout);
  }, [globalError]);

  return visible === true ? (
    <div className="toast">
      <span className="toast-message">{globalError}</span>
      <button className="toast-close" onClick={handleToastClick}>×</button>
    </div>
  ) : null;
};

export default Toast;
